<template>
  <div class="compliance-hero">
    <div class="container">
      <h1>{{ $t("compliance") }}</h1>
      <h2 class="suppliers__subtitle">
        {{ $t("complianceContent") }}
      </h2>
      <!-- <a href="#compliance-form" class="btn" @click="scrollToVacancy">Оставить обращение</a> -->
      <a @click="scrollToCompliance" class="compliance-btn">{{
        $t("complianceButton")
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplianceHero",
  methods: {
    scrollToCompliance() {
      document
        .querySelector(".compliance-form")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
